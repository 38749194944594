import React, { useEffect, useState } from "react";

import { Card, CardBody } from "reactstrap";

import { api, components, sharedHelper, data } from "@crewos/shared";

import poweredCrewOS from "../assets/img/logo_powered.svg";
import brand from "../assets/img/logoPrimary.png";
import powered from "../assets/img/powered.svg";

import BaseForm from "./BaseForm";

const { useAuth, AUTH_ACTIONS } = data;
const { authApi } = api;
const { Loader, TwoFASetupModal, TwoFAForm } = components;

const LoginForm = () => {
  const [, setAuthContext] = useAuth();

  const [loginLogo, setLoginLogo] = useState();
  const [twoFAData, setTwoFAData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const logo = await authApi.loginLogo();
        setLoginLogo(logo || brand);
      } catch (err) {
        sharedHelper.errorToast(err);
      }
    };
    fetchData();
  }, []);

  const onLoginComplete = (result) => {
    if (result.twoFABlock) {
      setTwoFAData(result);
    } else {
      setAuthContext({
        action: AUTH_ACTIONS.SET_USER_DATA,
        payload: { userData: result },
      });
    }
  };

  const on2FAComplete = (result) => {
    if (result.twoFABlock) {
      setTwoFAData(result);
    } else {
      setAuthContext({
        action: AUTH_ACTIONS.SET_USER_DATA,
        payload: { userData: result },
      });
    }
  };

  return (
    <div className="d-flex flex-column h-100 justify-content-between">
      <Card className="login-box mx-auto bg-white">
        <CardBody className="form-container mx-auto d-flex flex-column align-items-center">
          <div
            style={{ minHeight: 250 }}
            className="d-flex align-items-center justify-content-around flex-column flex-nowrap"
          >
            {loginLogo ? (
              <img
                src={loginLogo}
                className="my-3"
                style={{ maxHeight: 200, width: "auto", maxWidth: "100%" }}
                alt="CrewOS"
                data-testid="login-logo"
              />
            ) : (
              <div
                style={{ minHeight: 50 }}
                className="d-flex align-items-center"
              >
                <Loader size="sm" />
              </div>
            )}
            <h1 className="mt-3 mb-5 text-primary">Welcome Back!</h1>
          </div>
          {twoFAData ? (
            twoFAData.twoFAForced ? (
              <TwoFASetupModal
                defaultTwoFASetup={{
                  qrcode: twoFAData.qrcode,
                  secret: twoFAData.secret,
                }}
                userId={twoFAData.userId}
                onSubmit={(userData) => {
                  sharedHelper.successToast("2FA enabled");
                  setAuthContext({
                    action: AUTH_ACTIONS.SET_USER_DATA,
                    payload: { userData },
                  });
                }}
                onClose={() => {
                  setTwoFAData();
                }}
              />
            ) : (
              <TwoFAForm
                onComplete={on2FAComplete}
                onBack={() => setTwoFAData()}
                {...twoFAData}
              />
            )
          ) : (
            <BaseForm onComplete={onLoginComplete} />
          )}
        </CardBody>
      </Card>
      <div className="d-flex flex-column mx-auto pb-4 align-items-end">
        <img alt={"poweredCrewOS1"} className="powered" src={powered} />
        <img alt={"poweredCrewOS2"} src={poweredCrewOS} />
      </div>
    </div>
  );
};

export default LoginForm;
