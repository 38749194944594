import React, { useEffect, useState } from "react";

import {
  ListGroup,
  ListGroupItem,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Table,
  Form,
} from "reactstrap";

import { useUpdateSmartReport } from "../api/Reports.hooks";
import { sharedHelper, components, data } from "@crewos/shared";

import { DebounceInput } from "react-debounce-input";

const { CustomCheckbox, Select, Icon } = components;

const { useAuth, AUTH_ACTIONS } = data;

const replaceAESDecrypt = (inputString) => {
  const regex = /CONVERT\(AES_DECRYPT\(([^,]+), '([^']+)'\) USING 'UTF8MB4'\)/g;
  return inputString.replace(regex, "$1");
};

const SmartReportDetailModal = ({ report, isReadOnly, onClose, onSubmit }) => {
  const [authContext, setAuthContext] = useAuth();

  const { data: updateReportData, update: updateReport } =
    useUpdateSmartReport();

  const [name, setName] = useState(report.name);
  const [editQuery, setEditQuery] = useState();
  const [query, setQuery] = useState(report.query);
  const [columns, setColumns] = useState([...report.columns]);
  const [showSidebar, setShowSidebar] = useState(report.showSidebar);
  const [addColumn, setAddColumn] = useState();
  const [newColumn, setNewColumn] = useState({});

  useEffect(() => {
    if (updateReportData) {
      const userData = { ...authContext.userData };
      const showSidebar = report.showSidebar && report.showSidebar !== "false";
      const index = userData.smartReports.findIndex((r) => r.id === report.id);
      if (showSidebar) {
        if (index === -1) {
          //add
          userData.smartReports.push(report);
        } else {
          //replace
          userData.smartReports.splice(
            userData.smartReports.findIndex((r) => r.id === report.id),
            1,
            report
          );
        }
      } else if (index !== -1) {
        //remove
        userData.smartReports.splice(index, 1);
      }
      setAuthContext({
        action: AUTH_ACTIONS.SET_USER_DATA,
        payload: { userData },
      });
      sharedHelper.successToast(`Report saved`);
      onSubmit();
    }
  }, [
    updateReportData,
    onSubmit,
    columns,
    report,
    showSidebar,
    authContext.userData,
    setAuthContext,
  ]);

  const doSubmit = (evt) => {
    evt.preventDefault();
    const reportColumns = [...columns];
    if (Object.keys(newColumn).length) {
      reportColumns.push(newColumn);
    }
    updateReport({
      ...report,
      columns: reportColumns,
      name,
      query,
      showSidebar: `${Boolean(showSidebar)}`,
    });
  };

  const columnTypes = [
    "TEXT",
    "NUMBER",
    "PERCENTAGE",
    "CURRENCY",
    "DATE",
    "DATETIME",
  ].map((option) => ({
    label: option,
    value: option,
  }));

  return (
    <Modal isOpen={true} onClosed={onClose} size="lg">
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        Smart Report
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody>
          <Table className="col-12 px-0 mb-0" striped>
            <thead>
              <tr className="bg-graylight small">
                <th className="text-start">Name</th>
                {!isReadOnly ? <th className="text-end">Prompt</th> : null}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-start">
                  <DebounceInput
                    debounceTimeout={900}
                    placeholder="Enter a name"
                    className="form-control form-control-sm"
                    maxLength="25"
                    type="text"
                    size="sm"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </td>
                {!isReadOnly ? (
                  <td className="text-end">
                    <i>{report.prompt || "-"}</i>
                  </td>
                ) : null}
              </tr>
            </tbody>
          </Table>
          <Table className="col-12 px-0 mb-0" striped>
            <thead>
              <tr className="bg-graylight small">
                <th>Description</th>
                {!isReadOnly ? (
                  <th className="text-end">Sidebar Link</th>
                ) : null}
              </tr>
            </thead>
            <tbody className="small">
              <tr>
                <td>{report.description || "-"}</td>
                {!isReadOnly ? (
                  <td onClick={() => setShowSidebar(!showSidebar)}>
                    <div className="d-flex justify-content-end">
                      <CustomCheckbox checked={showSidebar} />
                    </div>
                  </td>
                ) : null}
              </tr>
            </tbody>
          </Table>
          {!isReadOnly ? (
            <Table className="col-12 px-0 mb-0" striped>
              <thead>
                <tr className="bg-graylight small">
                  <th>Query</th>
                </tr>
              </thead>
              <tbody className="small">
                <tr>
                  <td>
                    <div className="d-flex justify-content-between align-items-center">
                      {editQuery ? (
                        <textarea
                          className="form-control"
                          required={true}
                          rows={4}
                          placeholder="Enter a query"
                          value={query}
                          onChange={(e) => setQuery(e.target.value)}
                        />
                      ) : (
                        <code>{replaceAESDecrypt(report.query)}</code>
                      )}
                      {!editQuery ? (
                        <Button
                          size="sm"
                          onClick={() => setEditQuery(true)}
                          color="primary"
                        >
                          Edit
                        </Button>
                      ) : null}
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          ) : null}
          {!isReadOnly ? (
            <ListGroup flush className="col-12 px-0">
              <ListGroupItem
                className="d-flex justify-content-between align-items-center p-2 bg-graylight border-0 border-radius-default fw-bolder small"
                tag="div"
              >
                <span>Columns</span>
                <Button
                  size="sm"
                  onClick={() => setAddColumn(true)}
                  color="primary"
                >
                  Add
                </Button>
              </ListGroupItem>
              <ListGroupItem className="p-2 border-0" tag="div">
                <Table className="col-12 px-0 mb-0 mt-2 small" size="sm">
                  <thead>
                    <tr className="bg-graylight">
                      <th className="text-start fw-normal">Label</th>
                      <th className="text-center fw-normal">Accessor</th>
                      <th className="text-center fw-normal">Order</th>
                      <th className="text-center fw-normal">Type</th>
                      <th className="text-center fw-normal">Enabled</th>
                      <th className="text-end fw-normal"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {columns
                      .sort((x, y) => x.order - y.order)
                      .map((column, index) => {
                        const defaultType = column.type
                          ? { value: column.type, label: column.type }
                          : null;
                        return (
                          <tr key={column.accessor}>
                            <td className="text-center">
                              <DebounceInput
                                debounceTimeout={900}
                                placeholder="Enter a label"
                                className="form-control form-control-sm"
                                maxLength="50"
                                type="text"
                                size="sm"
                                value={column.label}
                                onChange={(e) => {
                                  column.label = e.target.value;
                                  setColumns([...columns]);
                                }}
                              />
                            </td>
                            <td className="text-center">{column.accessor}</td>
                            <td className="text-center">{column.order}</td>
                            <td className="text-center">
                              <Select
                                id="typeSelect"
                                name="typeSelect"
                                data-testid="type-select"
                                value={defaultType}
                                options={columnTypes}
                                onChange={(selected) => {
                                  column.type = selected.value;
                                  setColumns([...columns]);
                                }}
                              />
                            </td>
                            <td>
                              <div
                                className="min-width-50 d-flex justify-content-center"
                                onClick={() => {
                                  column.enabled = !column.enabled;
                                  setColumns([...columns]);
                                }}
                              >
                                <CustomCheckbox checked={column.enabled} />
                              </div>
                            </td>
                            <td className="text-end">
                              <Icon
                                size="sm"
                                name="trash"
                                className="cursor-pointer text-danger"
                                onClick={() => {
                                  const newColumns = [...columns];
                                  newColumns.splice(index, 1);
                                  debugger;
                                  setColumns([...newColumns]);
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    {addColumn ? (
                      <tr>
                        <td className="text-center">
                          <DebounceInput
                            debounceTimeout={900}
                            placeholder="Enter a label"
                            className="form-control form-control-sm"
                            maxLength="50"
                            type="text"
                            size="sm"
                            required
                            value={newColumn.label}
                            onChange={(e) => {
                              setNewColumn({
                                ...newColumn,
                                label: e.target.value,
                              });
                            }}
                          />
                        </td>
                        <td className="text-center">
                          <DebounceInput
                            debounceTimeout={900}
                            placeholder="Enter an accessor"
                            className="form-control form-control-sm"
                            maxLength="50"
                            type="text"
                            size="sm"
                            required
                            value={newColumn.accessor}
                            onChange={(e) => {
                              setNewColumn({
                                ...newColumn,
                                accessor: e.target.value,
                              });
                            }}
                          />
                        </td>
                        <td className="text-center">
                          <DebounceInput
                            debounceTimeout={900}
                            placeholder="Enter an order"
                            className="form-control form-control-sm"
                            min={0}
                            type="number"
                            size="sm"
                            required
                            value={newColumn.order}
                            onChange={(e) => {
                              setNewColumn({
                                ...newColumn,
                                order: e.target.value,
                              });
                            }}
                          />
                        </td>
                        <td className="text-center">
                          <Select
                            id="newTypeSelect"
                            name="newTypeSelect"
                            data-testid="new-type-select"
                            placeholder="Select a type"
                            value={
                              newColumn.type
                                ? {
                                    value: newColumn.type,
                                    label: newColumn.type,
                                  }
                                : null
                            }
                            options={columnTypes}
                            onChange={(selected) => {
                              setNewColumn({
                                ...newColumn,
                                type: selected.value,
                              });
                            }}
                          />
                        </td>
                        <td className="text-end">
                          <div
                            className="min-width-50 d-flex justify-content-center"
                            onClick={() => {
                              setNewColumn({
                                ...newColumn,
                                enabled: !newColumn.enabled,
                              });
                            }}
                          >
                            <CustomCheckbox checked={newColumn.enabled} />
                          </div>
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </Table>
              </ListGroupItem>
            </ListGroup>
          ) : null}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose}>
            Close
          </Button>
          {!isReadOnly ? (
            <Button color="primary" type="submit">
              Save
            </Button>
          ) : null}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default SmartReportDetailModal;
